import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Chip,
  Checkbox,
} from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import * as Yup from 'yup';
import { MenuItem } from 'material-ui';
import sizes from './adManagerSizes';

const SpotsForm = ({ onSubmit, initialData }) => {
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        isSiprocal: initialData ? initialData.isSiprocal : false,
        campaignSpotKey: initialData ? initialData.campaignSpotKey : '',
        campaignSpotDescription: initialData
          ? initialData.campaignSpotDescription
          : '',
        adManagerSlotName: initialData ? initialData.adManagerSlotName : '',
        siprocalSlotName: initialData ? initialData.siprocalSlotName : '',
        siprocalSizeArray: initialData
          ? JSON.parse(initialData.siprocalSizeArray)
          : [],
        adManagerSizeArray: initialData
          ? JSON.parse(initialData.adManagerSizeArray)
          : [],
      }}
      validationSchema={Yup.object().shape({
        campaignSpotKey: Yup.string().required('Campo obrigatório'),
        campaignSpotDescription: Yup.string().required('Campo obrigatório'),
      })}
    >
      {({ values, handleChange, errors, touched, isValid, setFieldValue }) => (
        <Form>
          <Card title="Spot">
            <TextField
              margin="normal"
              name="campaignSpotKey"
              type="text"
              label="Chave do spot - D5"
              variant="outlined"
              helperText="Caixa alta, sem espaços. Será usado em conjunto com o frontend para exibir o anúncio no local adequado. Valor será usado para descrição do evento que será disparado para o Analytics"
              fullWidth
              value={values.campaignSpotKey}
              onChange={handleChange}
              error={errors.campaignSpotKey && touched.campaignSpotKey}
              InputLabelProps={{ shrink: true }}
            />

            <ErrorMessage name="campaignSpotKey" />

            <TextField
              name="campaignSpotDescription"
              type="text"
              margin="normal"
              label="Descrição do spot - D5"
              variant="outlined"
              helperText="Valor será usado para evento do Analytics. Ex.: Super Banner Home 728x90"
              fullWidth
              value={values.campaignSpotDescription}
              onChange={handleChange}
              error={
                errors.campaignSpotDescription &&
                touched.campaignSpotDescription
              }
              InputLabelProps={{ shrink: true }}
            />

            <ErrorMessage name="campaignSpotDescription" />

            <TextField
              name="adManagerSlotName"
              type="text"
              margin="normal"
              label="Código do slot no AdManager - AdM"
              variant="outlined"
              helperText="Ex.: /1056794/draft5.gg/BACKGROUND_TAKEOVER_LEFT"
              fullWidth
              value={values.adManagerSlotName}
              onChange={handleChange}
              error={errors.adManagerSlotName && touched.adManagerSlotName}
              InputLabelProps={{ shrink: true }}
            />

            <ErrorMessage name="adManagerSlotName" />

            <InputLabel>
              <Checkbox
                name="isSiprocal"
                checked={values.isSiprocal}
                onChange={handleChange}
                color="primary"
              />
              Ativar tag Siprocal?
            </InputLabel>

            <TextField
              name="siprocalSlotName"
              type="text"
              margin="normal"
              label="Código da tag da Siprocal - Siprocal"
              variant="outlined"
              helperText="Ex.: LDI3K1AEQD68991Q0L7D7J44U0"
              fullWidth
              value={values.siprocalSlotName}
              onChange={handleChange}
              error={errors.siprocalSlotName && touched.siprocalSlotName}
              InputLabelProps={{ shrink: true }}
            />

            <ErrorMessage name="siprocalSlotName" />

            <FormControl style={{ width: '300px' }}>
              <InputLabel id="adManagerSizeArrayLabel">
                Tamanhos AdManager
              </InputLabel>
              <Select
                multiple
                labelId="adManagerSizeArrayLabel"
                id="adManagerSizeArray"
                value={values.adManagerSizeArray.map(size =>
                  JSON.stringify(size)
                )}
                onChange={ev => {
                  const { value } = ev.target;
                  setFieldValue(
                    'adManagerSizeArray',
                    value.map(v => JSON.parse(v))
                  );
                }}
                input={
                  <OutlinedInput
                    block
                    style={{ minWidth: '100%' }}
                    label="Tamanhos"
                  />
                }
                renderValue={size => (
                  <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {size.map(value => {
                      const spotParsed = JSON.parse(value);
                      return (
                        <Chip
                          style={{ margin: '4px' }}
                          key={value}
                          label={
                            Array.isArray(spotParsed)
                              ? spotParsed.join('x')
                              : spotParsed
                          }
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {sizes
                  .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                  .map(spot => {
                    const spotValue = JSON.stringify(spot);
                    return (
                      <MenuItem
                        key={spotValue}
                        value={spotValue}
                        style={{
                          fontWeight: values.adManagerSizeArray.some(
                            s => JSON.stringify(s) === spotValue
                          )
                            ? 'bold'
                            : 'normal',
                        }}
                      >
                        {Array.isArray(spot) ? spot.join('x') : spot}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl style={{ width: '300px' }}>
              <InputLabel id="siprocalSizeArrayLabel">
                Tamanhos Siprocal
              </InputLabel>
              <Select
                multiple
                labelId="siprocalSizeArrayLabel"
                id="siprocalSizeArray"
                value={values.siprocalSizeArray.map(size =>
                  JSON.stringify(size)
                )}
                onChange={ev => {
                  const { value } = ev.target;
                  setFieldValue(
                    'siprocalSizeArray',
                    value.map(v => JSON.parse(v))
                  );
                }}
                input={
                  <OutlinedInput
                    block
                    style={{ minWidth: '100%' }}
                    label="Tamanhos"
                  />
                }
                renderValue={size => (
                  <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {size.map(value => {
                      const spotParsed = JSON.parse(value);
                      return (
                        <Chip
                          style={{ margin: '4px' }}
                          key={value}
                          label={
                            Array.isArray(spotParsed)
                              ? spotParsed.join('x')
                              : spotParsed
                          }
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {sizes
                  .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                  .map(spot => {
                    const spotValue = JSON.stringify(spot);
                    return (
                      <MenuItem
                        key={spotValue}
                        value={spotValue}
                        style={{
                          fontWeight: values.siprocalSizeArray.some(
                            s => JSON.stringify(s) === spotValue
                          )
                            ? 'bold'
                            : 'normal',
                        }}
                      >
                        {Array.isArray(spot) ? spot.join('x') : spot}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Card>

          <Button
            disabled={!isValid}
            size="large"
            block
            skin="success"
            text="Enviar"
          />
        </Form>
      )}
    </Formik>
  );
};

export default SpotsForm;
