import React, { Fragment, useState } from 'react';
import Button from '../../components/Button';
import Header from '../../components/Header';
import AdminToolsService from '../../services/admin-tools';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../components/ModalConfirmation';
import FontAwesome from 'react-fontawesome';

import '../../components/Table/styles.css';

function resetRedisCache() {
  return AdminToolsService.resetRedisCache()
    .then(() => {
      toast('Cache do Redis resetado com sucesso.', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });
    })
    .catch(e => {
      console.error('Falha ao resetar o cache do Redis. Erro:', e);
      toast('Falha ao resetar o cache do Redis.', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    });
}

export default function AdminToolsPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');

  const handleOpenModal = (action, description) => {
    setSelectedAction(() => action);
    setSelectedDescription(description);
    setModalOpen(true);
  };

  const handleConfirm = () => {
    if (selectedAction) {
      selectedAction();
    }
    setModalOpen(false);
  };

  const adminToolsItems = [
    {
      option: 'RedisRR',
      description:
        'Esta ação irá reiniciar o sistema Redis e limpar todo o cache do site.',
      action: () =>
        handleOpenModal(
          resetRedisCache,
          'Site ficará lento momentaneamente. Deseja continuar?'
        ),
    },
  ];

  return (
    <Fragment>
      <Header title="Ferramentas Administrativas" />
      <div className="PageContainer">
        <div className="Alert Alert__Error">
          <FontAwesome name="ban" className="Alert__Icon" />
          <b>Aviso:</b> As opções aqui listadas são administrativas e com grande
          impacto no funcionamento do site. <b>Não utilize nenhuma</b> delas sem
          ter certeza do que está fazendo!
        </div>

        <table className="Table">
          <thead className="Table__thead">
            <tr>
              <td>Opção</td>
              <td>Descrição</td>
              <td>Ações</td>
            </tr>
          </thead>
          <tbody className="Table__tbody">
            {adminToolsItems.map(item => (
              <tr key={item.option}>
                <td>{item.option}</td>
                <td>{item.description}</td>
                <td>
                  <Button
                    text="Executar"
                    size="small"
                    skin="accent"
                    onClick={item.action}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmationDialog
        open={modalOpen}
        title="Tem certeza?"
        description={selectedDescription}
        onConfirm={handleConfirm}
        onClose={() => setModalOpen(false)}
      />
    </Fragment>
  );
}
