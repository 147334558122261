import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';

const ConfirmationDialog = ({
  open,
  title,
  description,
  onConfirm,
  onClose,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClose}
        autoFocus
      >
        Cancelar
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
